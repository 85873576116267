<template lang="pug">
    div
      .content-loader-center.m-0.h-100(v-if='isLoadingCustomFieldTemplate || isCreatingCustomFieldTemplate || isUpdatingCustomFieldTemplate')
        .text-center.flex-center
          .loading-bg-inner 
            .loader
              .outer
              .middle
              .inner
          .mt-5
            br
            br
            br
            | {{ isLoadingCustomFieldTemplate ? 'Chargement du formulaire de contrat' : 'Enregistrement du formulaire de contrat...' }}
      div.h-100(v-else)
        b-row(class="m-0 justify-content-center")
          .d-flex.align-items-center.justify-content-between.w-75.p-1.pl-0
            .d-flex.align-items-center
              h2.content-header-title.float-left.m-0.pr-1.mr-1(style="border-right: 1px solid #e2e2e2")
                | Contrat
              .content-header-search.mr-1
                h3.m-0(style='text-transform: capitalize')
                  | {{ dataForm.label }}
                  span.ml-50.text-danger(v-show="archive") (archive)
            .content-header-actions.d-flex
              feather-icon.cursor-pointer(
                icon="XIcon"
                size="24"
                @click="cancel()"
              )
        b-row.m-0.justify-content-center(style="height: calc(100% - 57px);")
            b-col(cols='9')
              div(:id="'formEditContract'+key" style="height: calc(100% - 57px);")
                .d-flex
                  .d-flex.my-0.w-100
                    .d-flex.flex-column.py-0.w-100
                      b-form-group.mb-1(label='Nom du contrat' label-for='label')
                        ejs-textbox(id="label" v-model='dataForm.label')
                  .d-flex.my-0.w-100.ml-1
                    .d-flex.flex-column.py-0.w-100
                      b-form-group.mb-1(label='Nature' label-for='nature')
                        ejs-dropdownlist(id="nature" v-model='dataForm.nature' :dataSource="contractNature" :fields="{ text: 'label', value: 'value' }")
                  .d-flex.my-0.w-100.ml-1
                    .d-flex.flex-column.py-0.w-100
                      b-form-group.mb-1(label='Statut' label-for='status')
                        ejs-dropdownlist(id="status" v-model='dataForm.status' :dataSource="contractStatus" :fields="{ text: 'label', value: 'value' }")
                .d-flex.mt-2
                  .d-flex.my-0.w-100
                    .d-flex.flex-column.py-0.w-100
                      b-form-group.mb-1(label='Heures par jour' label-for='hoursDayDefault')
                        ejs-numerictextbox(id="hoursDayDefault" v-model='dataForm.hoursDayDefault' @blur="isNull('hoursDayDefault')" format="##.## h" :decimals="2")
                  .d-flex.my-0.w-100.ml-1
                    .d-flex.flex-column.py-0.w-100
                      b-form-group.mb-1(label='Seuil d\'alerte par jour' label-for='hoursDayWarning')
                        ejs-numerictextbox(id="hoursDayWarning" v-model='dataForm.hoursDayWarning' @blur="isNull('hoursDayWarning')" format="##.## h" :decimals="2")
                  .d-flex.my-0.w-100.ml-1
                    .d-flex.flex-column.py-0.w-100
                      b-form-group.mb-1(label='Limite d\'heures par jour' label-for='hoursDayLimit')
                        ejs-numerictextbox(id="hoursDayLimit" v-model='dataForm.hoursDayLimit' @blur="isNull('hoursDayLimit')" format="##.## h" :decimals="2")
                .d-flex
                  .d-flex.my-0.w-100
                    .d-flex.flex-column.py-0.w-100
                      b-form-group.mb-1(label='Heures par semaine' label-for='hoursWeekDefault')
                        ejs-numerictextbox(id="hoursWeekDefault" v-model='dataForm.hoursWeekDefault' @blur="isNull('hoursWeekDefault')" format="##.## h" :decimals="2")
                  .d-flex.my-0.w-100.ml-1
                    .d-flex.flex-column.py-0.w-100
                      b-form-group.mb-1(label='Seuil d\'alerte par semaine' label-for='hoursWeekWarning')
                        ejs-numerictextbox(id="hoursWeekWarning" v-model='dataForm.hoursWeekWarning' @blur="isNull('hoursWeekWarning')" format="##.## h" :decimals="2")
                  .d-flex.my-0.w-100.ml-1
                    .d-flex.flex-column.py-0.w-100
                      b-form-group.mb-1(label='Limite d\'heures par semaine' label-for='hoursWeekLimit')
                        ejs-numerictextbox(id="hoursWeekLimit" v-model='dataForm.hoursWeekLimit' @blur="isNull('hoursWeekLimit')" format="##.## h" :decimals="2")

              .d-flex.flex-column
                vs-divider.mb-50.mt-2(color='#cccccc')
                b-row.my-0
                    b-col.py-0(cols='12')
                        .d-flex.justify-content-between
                            div
                                ejs-button(@click.native='cancel()' content='Annuler' :isPrimary='true' locale='fr' cssClass='e-outline mr-1')
                                ejs-button(@click.native='archiveContractLocal(dataForm)' v-if='dataForm.id && !archive' content='' cssClass='e-outline e-danger m-0' iconCss='e-btn-sb-icons e-icon-archive' :isPrimary='true' locale='fr')
                                ejs-button(@click.native='deleteContractLocal(dataForm)' v-if='dataForm.id && archive' content='' cssClass='e-outline e-danger m-0' iconCss='e-btn-sb-icons e-icon-trash' :isPrimary='true' locale='fr')
                                ejs-button(@click.native='restoreContractLocal(dataForm)' v-if='dataForm.id && archive' content='' cssClass='e-outline e-warning m-0 ml-1' iconCss='e-btn-sb-icons e-icon-restore' :isPrimary='true' locale='fr')
                            ejs-button(@click.native='confirm()' :content='dataForm.id ? "Enregistrer" : "Ajouter"' :isPrimary='true' locale='fr' iconCss='e-btn-sb-icons e-icons e-save' iconPosition='Right')
    </template>
<script>
import { mapGetters, mapActions } from "vuex";
import { contractStatus, contractNature } from "@/types/api-orisis/enums/enums";
import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { FormValidator } from "@syncfusion/ej2-vue-inputs";
import EditCustomField from "@/components/custom-field/EditCustomField.vue";
import HeaderEditCustomField from "@/components/custom-field/HeaderEditCustomField.vue";
import customFieldType from "@/types/api-orisis/enums/customFieldType";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);
import { customFieldTemplateType } from "@/types/api-orisis/enums/enums";
import { InPlaceEditorComponent } from "@syncfusion/ej2-vue-inplace-editor";

export default {
  components: {
    EditCustomField,
    HeaderEditCustomField,
    SyncGrid: () => import("@/components/global/grid/Grid.vue"),
    "ejs-inplaceeditor": InPlaceEditorComponent,
  },
  props: {
    id: {
      default: 0,
    },
    archive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contractNature,
      contractStatus,
      customFieldType,
      customFieldTemplateType,
      // Gestion du form principal
      name: "editCustomFieldTemplate",
      dateRangeDefault: null, //[new Date(dayjs().format("YYYY-MM-DD")).toISOString(), new Date(dayjs().format("YYYY-MM-DD")).toISOString()],
      dataForm: {
        id: 0,
        label: "",
        hoursDayDefault: 0,
        hoursDayLimit: 0,
        hoursDayWarning: 0,
        hoursWeekDefault: 0,
        hoursWeekLimit: 0,
        hoursWeekWarning: 0,
        nature: 0,
        status: 0,
      },
      dataOrigine: {},
      loading: true,
      dataCompany: {},
      dataCompanyLoading: true,
      key: 1,
      groupActive: 0,
      headerData: [],
      tagguerButton: false,
      allowGrouping: false,
      allowPaging: false,
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        titleAdd: "Nouveau champs",
        titleEdit: "Modifier le champs",
        titleField: "customField",
        mode: "Dialog",
        template: function () {
          return {
            template: EditCustomField,
          };
        },
      },

      formObj: "",
      options: {},
    };
  },
  computed: {
    ...mapGetters([
      "userCollaboratorId",
      "isLoadingCustomFieldTemplate",
      "isCreatingCustomFieldTemplate",
      "isUpdatingCustomFieldTemplate",
    ]),
  },

  async created() {
    if (this.id > 0) {
      if (this.archive) {
        this.getContractArchivedById({ contractId: this.id }).then((res) => {
          this.dataForm = res;
          this.dataOrigine = JSON.stringify(this.dataForm);
          this.key++;
          this.formObj = null;
          this.loading = false;
          this.$nextTick(() => {
            this.formObj = new FormValidator(
              "#formEditContract" + this.key,
              this.options
            );
          });
        });
      } else {
        this.getContractById({ contractId: this.id }).then((res) => {
          this.dataForm = res;
          this.dataOrigine = JSON.stringify(this.dataForm);
          this.key++;
          this.formObj = null;
          this.loading = false;
          this.$nextTick(() => {
            this.formObj = new FormValidator(
              "#formEditContract" + this.key,
              this.options
            );
          });
        });
      }
    } else {
      this.initializeForm();
    }
  },
  mounted() {
    if (this.$route.meta.mode == "single" && this.userCollaboratorId) {
      this.dataForm.collaboratorId = parseInt(this.userCollaboratorId);
    }
  },
  methods: {
    ...mapActions([
      "getContractById",
      "archiveContracts",
      "restoreContracts",
      "deleteContracts",
      "createContract",
      "updateContract",
      "getContractArchivedById",
    ]),
    isNull(id) {
      const e = document.getElementById(id);
      const min = e.getAttribute("aria-valuemin");
      if (e.value == "") {
        e.value = min + " h";
      }
    },
    formatCurrency,
    async deleteButtonClicked(args) {
      this.dataForm.customFieldGroups[this.groupActive].customFields.splice(
        args.rowIndex,
        1
      );
      this.$refs[
        "overviewgrid" + this.groupActive
      ][0].$refs.overviewgrid.closeEdit();
      if (args.rowData.id > 0) {
        // On supprime de la base le champs custom
        this.deleteCustomFields({ customFieldGroupIds: [args.rowData.id] });
      }
      this.$refs[
        "overviewgrid" + this.groupActive
      ][0].$refs.overviewgrid.refresh();
    },
    initializeForm() {
      this.loading = true;
      this.dataForm = {
        id: 0,
        label: null,
        hoursDayDefault: 0,
        hoursDayLimit: 0,
        hoursDayWarning: 0,
        hoursWeekDefault: 0,
        hoursWeekLimit: 0,
        hoursWeekWarning: 0,
        nature: 0,
        status: 0,
      };
      this.dataOrigine = JSON.stringify(this.dataForm);

      this.key++;
      this.formObj = null;
      this.loading = false;
      this.$nextTick(() => {
        this.formObj = new FormValidator(
          "#formEditContract" + this.key,
          this.options
        );
      });
    },
    async confirm(andNew = 0) {
      let formStatus = this.formObj.validate();
      if (formStatus) {
        //this.formObj.element.reset();
        // this.$refs.dialogObj.show();
        this.$store.commit("SET_IS_CREATING_CONTRACT", true);
        if (this.dataForm.id) {
          await this.updateContract({ contract: this.dataForm });
        } else {
          await this.createContract({ contract: this.dataForm });
        }
        this.$store.commit("SET_IS_CREATING_CONTRACT", false);
        this.$tabs.close({ to: "/parameters/contracts" });
      }
    },
    cancel() {
      if (this.dataOrigine == JSON.stringify(this.dataForm)) {
        if (this.$route.params.routeOrigine) {
          this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
        } else {
          this.$tabs.close({ to: "/parameters/contracts" });
        }
      } else {
        this.$bvModal
          .msgBoxConfirm("Cette action est définitive et irréversible.", {
            title:
              "Êtes-vous sûr de vouloir quitter l'édition sans enregistrer ?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              if (this.$route.params.routeOrigine) {
                this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
              } else {
                this.$tabs.close();
              }
            }
          });
      }
    },
    archiveContractLocal(data) {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title: "Êtes-vous sûr de vouloir archiver ce contrat ?",
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.archiveContracts({ contractIds: [data.id] });
            this.$tabs.close({ to: "/parameters/contracts" });
          }
        });
    },
    deleteContractLocal(data) {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title: "Êtes-vous sûr de vouloir supprimer ce contrat ?",
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteContracts({ contractIds: [data.id] });
            this.$tabs.close({ to: "/parameters/contracts" });
          }
        });
    },
    restoreContractLocal(data) {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title: "Êtes-vous sûr de vouloir restaurer ce contrat ?",
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.restoreContracts({ contractIds: [data.id] });
            this.$tabs.close({ to: "/parameters/contracts" });
          }
        });
    },
  },
};
</script>
<style lang="scss">
.e-chip-list .e-chip:last-child {
  margin-right: 0px !important;
}
h4 .e-editable-value {
  border-bottom: 1px dashed #212529;
  font-weight: 500 !important;
  line-height: 1.2 !important;
  font-size: 1.286rem !important;
  color: #5e5873 !important;
}
h4 .e-component-group {
  margin-bottom: 0px !important;
}
</style>
